import { FormProvider, useForm } from "react-hook-form";
import "./App.css";
import Header from "./components/header/Header";
import Map from "./components/map/Map";
import SideButtons from "./components/sideButtons/SideButtons";
import GeoJSONProvider from "./context/GeoJSONContext";
import SpatialPlansProvider from "./context/SpatialPlansContext";
import ToolsProvider from "./context/ToolsContext";

export interface HeaderMenuForm {
  basicModules: {
    cadastre: {
      parcels: boolean;
      buildings: boolean;
      streets: boolean;
    };
    spatialPlan: {
      prometnice: boolean;
      naciniGradnje: boolean;
      koristenje: boolean;
      mreza: boolean;
      voda: boolean;
      otpadnaVoda: boolean;
      odvodnjaKise: boolean;
      oblici: boolean;
      prijedlog: boolean;
    };
  };
  communalEconomy: {
    objectEvidentation: boolean;
    communalFee: boolean;
  };
  communalInfrastructure: {
    publicIllumination: boolean;
  };
  other: {
    advertisementBoards: boolean;
    localStateProperty: boolean;
  };
}
function App() {
  const formMethods = useForm<HeaderMenuForm>({
    defaultValues: {
      basicModules: {
        cadastre: {
          parcels: true,
          buildings: false,
        },
        spatialPlan: {
          prometnice: false,
          naciniGradnje: false,
          koristenje: false,
          mreza: false,
          voda: false,
          otpadnaVoda: false,
          odvodnjaKise: false,
          oblici: false,
          prijedlog: false,
        },
      },
      communalEconomy: {
        objectEvidentation: false,
        communalFee: false,
      },
      communalInfrastructure: {
        publicIllumination: false,
      },
      other: {
        advertisementBoards: false,
        localStateProperty: false,
      },
    },
    mode: "all",
  });
  return (
    <SpatialPlansProvider>
      <GeoJSONProvider>
        <ToolsProvider>
          <FormProvider {...formMethods}>
            <Header />
            <SideButtons />
            <Map />
          </FormProvider>
        </ToolsProvider>
      </GeoJSONProvider>
    </SpatialPlansProvider>
  );
}

export default App;
