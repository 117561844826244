import { debounce } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { HeaderMenuForm } from "../../../../App";
import Checkbox from "../checkbox/Checkbox";
import "./HeaderMenu.css";
// Assets
import { BarLoader } from "react-spinners";
import CloseButtonIcon from "../../../../assets/svg/CloseButtonIcon.svg";
import SearchIcon from "../../../../assets/svg/SearchIcon.svg";
import { InfoModal } from "../../../map/components/infoModal/InfoModal";
import Modal from "../loadingModal/Modal";
import { getParcelByName, Parcel } from "./api/getParcelByName";
import { spacialPlansCheckboxData } from "./HeaderMenu.utils";
import { SpacialPlanSublayerList } from "./SpacialPlanSublayerList";

interface HeaderMenuProps {
  closeMenu?: () => void;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderMenu: React.FC<HeaderMenuProps> = ({ closeMenu, setOpenMenu }) => {
  const formMethods = useFormContext<HeaderMenuForm>();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [sugestions, setSugestions] = useState<Parcel[]>([]);
  const [showSugestions, setShowSugestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const modalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const search = (name: string) => {
    if (name) getParcelByName(name).then((res) => setSugestions(res.data));
  };

  const debounced = useCallback(debounce(search, 500), []);

  return (
    <div className="header-menu-container" ref={modalRef}>
      {modal && (
        <Modal title="Pričekajte download" onClose={() => setModal(false)} />
      )}
      {showModal && (
        <InfoModal
          setLoading={setLoading}
          loading={loading}
          ids={[query]}
          setDownloadModal={setModal}
          setModal={setShowModal}
          toggleModal={() => {
            setQuery("");
            setShowModal(!showModal);
          }}
        />
      )}
      <div className="menu-close-row">
        <button onClick={closeMenu} className="menu-close-button">
          <img alt="close" src={CloseButtonIcon} />
        </button>
      </div>
      <div className="menu-list">
        {/* Osnovni moduli section */}
        <div className="menu-list-section">
          <p className="menu-text-bold">Pretraži parcele po broju</p>
          {loading ? (
            <div className="bar-loader-container">
              <BarLoader
                color="#f7aa47"
                height={6}
                speedMultiplier={0.8}
                width={200}
              />
            </div>
          ) : (
            <>
              <div className="search-input-container">
                <div className="search-container">
                  <img alt="search" src={SearchIcon} className="search-icon" />
                  <input
                    value={query}
                    onFocus={() => setShowSugestions(true)}
                    onChange={(event) => {
                      setQuery(event.target.value);
                      debounced(event.target.value);
                    }}
                    name="search"
                    placeholder="Search"
                    className="search-input"
                  />
                  <button
                    style={{
                      backgroundColor:
                        sugestions.filter((item) => query === item.number)
                          .length === 0
                          ? "#bebebe"
                          : "#f7aa47",
                    }}
                    disabled={
                      sugestions.filter((item) => query === item.number)
                        .length === 0
                    }
                    className="search-button"
                    onClick={() => setShowModal(true)}
                  >
                    <p className="search-button-text">pretraži</p>
                  </button>
                </div>
              </div>
              {showSugestions && (
                <div
                  ref={dropdownRef}
                  style={{
                    position: "absolute",
                    top: 116,
                    backgroundColor: "white",
                    width: "80%",
                    border: "1px solid #8592a3",
                    borderRadius: 10,
                    flexDirection: "column",
                    display: "flex",
                    paddingTop: 16,
                    gap: 8,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    zIndex: 40,
                  }}
                >
                  {sugestions.length > 0 ? (
                    sugestions.map((data) => (
                      <div
                        key={data.number}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setQuery(data.number);
                          setShowSugestions(false);
                        }}
                      >
                        {data.number}
                      </div>
                    ))
                  ) : (
                    <div onClick={() => setShowSugestions(false)}>
                      Nema prijedloga parcela
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <p className="menu-text-bold">Katastar</p>
          <div className="checkbox-container">
            <Checkbox
              control={formMethods.control}
              name="basicModules.cadastre.parcels"
              label="Parcele"
              dot
              dotColor="orange"
            />
            <Checkbox
              control={formMethods.control}
              name="basicModules.cadastre.buildings"
              label="Zgrade"
              dot
              dotColor="blue"
            />
            <Checkbox
              control={formMethods.control}
              name="basicModules.cadastre.streets"
              label="Ulice"
              dot
              dotColor="yellow"
            />
          </div>
          <p className="menu-text-bold">Prostorni planovi</p>
          {spacialPlansCheckboxData.map((item) => (
            <div>
              <Checkbox
                key={item.name}
                name={item.name}
                control={formMethods.control}
                label={item.label}
              />
              {formMethods.watch(item.name) && (
                <SpacialPlanSublayerList
                  spatialPlansLayer={item.spatialPlanLayers}
                />
              )}
            </div>
          ))}
        </div>
        {/* Komunalno gospodarstvo section */}
        <div className="menu-list-section">
          <p className="menu-text-bold">Komunalno gospodarstvo</p>
          <Checkbox
            control={formMethods.control}
            name="communalEconomy.objectEvidentation"
            label="Evidentiranje objekata"
            dot
            dotColor="red"
          />
          <Checkbox
            control={formMethods.control}
            name="communalEconomy.communalFee"
            label="Komunalna naknada"
          />
        </div>
        {/* Komunalna infrastruktura section */}
        <div className="menu-list-section">
          <p className="menu-text-bold">Komunalna infrastruktura</p>
          <Checkbox
            control={formMethods.control}
            name="communalInfrastructure.publicIllumination"
            label="Javna rasvjeta"
          />
        </div>
        {/* Ostalo section */}
        <div className="menu-list-section">
          <p className="menu-text-bold">Ostalo</p>
          <Checkbox
            control={formMethods.control}
            name="other.advertisementBoards"
            label="Reklamni panoi"
          />
          <Checkbox
            control={formMethods.control}
            name="other.localStateProperty"
            label="Gradska i općinska imovina"
          />
        </div>
      </div>
    </div>
  );
};
export default HeaderMenu;
